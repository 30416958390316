import React from "react"
import { Router } from "@reach/router"
import Login from "../components/login"
import Register from "../components/register"
import Welcome from "../components/welcome"
import PrivateRoute from "../components/PrivateRoute"

const App = () => {
  return (
    <Router basepath="/app">
      <PrivateRoute path="/welcome" component={Welcome} />
      <Register path="/register" />
      <Login path="/login" />
    </Router>
  )
}

export default App
