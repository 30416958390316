import { gql } from "@apollo/client"

/**
 * Register customer mutation query.
 */
const REGISTER_CUSTOMER = gql`
  mutation RegisterCustomer($input: RegisterUserInput!) {
    registerUser(input: $input) {
      user {
        id
        username
        email
        firstName
        lastName
        jwtAuthToken
      }
    }
  }
`

export default REGISTER_CUSTOMER
