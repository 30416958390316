import React from "react"
import { sanitize } from "../../utils/functions"
import "./index.scss"

const MessageAlert = ({ message, success, onCloseButtonClick }) => {
  return (
    <div className="alert">
      <button
        type="button"
        onClick={onCloseButtonClick}
        className="alert__btn"
        data-dismiss="alert"
      >
        <small>&times;</small>
      </button>
      <span
        className={success ? "alert__content-success" : "alert__content-danger"}
        dangerouslySetInnerHTML={{ __html: sanitize(message) }}
      />
    </div>
  )
}

export default MessageAlert
