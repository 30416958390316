import React from "react"
import "./index.scss"

const Welcome = ({ username }) => {
  return (
    <div className="welcome-page">
      <h1>{`Welcome ${username}`}</h1>
      <h3>Click below to start building your decks</h3>
      <a href="https://chatdecks.simplyeffectiveweb.com/app/dashboard/" className="btn btn__dark">
        Start building
      </a>
    </div>
  )
}

export default Welcome
