import React, { useState, useContext } from "react"
import { navigate } from "gatsby"
import Avatar from "@material-ui/core/Avatar"
import Button from "../button"
import TextField from "@material-ui/core/TextField"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import MessageAlert from "../message-alert"
import cartSpinnerGif from "../../images/cart-spinner.gif"
import { setAuth } from "../../utils/functions"
import { AppContext } from "../context/AppContext"
import { isEmpty } from "lodash"
import validateAndSanitizeRegisterForm from "../../validator/register"
import { useMutation } from "@apollo/client"
import { v4 } from "uuid"
import REGISTER_CUSTOMER from "../../mutations/register"
import "./index.scss"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "calc(100vh - 100px)",
    justifyContent: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}))

const Register = () => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useContext(AppContext)
  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [showAlertBar, setShowAlertBar] = useState(true)

  // Redirect the user to My Account page if user is already validated.
  if (isUserLoggedIn) {
    navigate("/")
  }

  // Hide the Status bar on cross button click.
  const onCloseButtonClick = () => {
    setErrorMessage("")
    setShowAlertBar(false)
  }

  const setClientSideError = validationResult => {
    if (validationResult.errors.password) {
      setErrorMessage(validationResult.errors.password)
    }

    if (validationResult.errors.email) {
      setErrorMessage(validationResult.errors.email)
    }

    if (validationResult.errors.username) {
      setErrorMessage(validationResult.errors.username)
    }

    setShowAlertBar(true)
  }

  // Register Mutation.
  const [
    register,
    { loading: registerLoading, error: registerError },
  ] = useMutation(REGISTER_CUSTOMER, {
    variables: {
      input: {
        clientMutationId: v4(), // Generate a unique id.,
        username,
        password,
        email,
      },
    },
    onCompleted: data => {
      // If error.
      if (!isEmpty(registerError)) {
        //console.log(registerError)
        setErrorMessage(registerError.graphQLErrors[0].message)
      }

      const {
        registerUser: { user },
      } = data

      handleRegisterSuccess()

      const authData = {
        authToken: user.jwtAuthToken,
        user: user,
      }

      setAuth(authData)
      navigate("/app/welcome")
      setIsUserLoggedIn(true)
    },
    onError: error => {
      if (error) {
        if (!isEmpty(error)) {
          setErrorMessage(error.graphQLErrors[0].message)
        }
      }
    },
  })

  const handleRegister = async event => {
    if (process.browser) {
      event.preventDefault()

      // Validation and Sanitization.
      const validationResult = validateAndSanitizeRegisterForm({
        username,
        email,
        password,
      })

      // If the data is valid.
      if (validationResult.isValid) {
        setUsername(validationResult.sanitizedData.username)
        setPassword(validationResult.sanitizedData.password)
        setEmail(validationResult.sanitizedData.email)

        register()
      } else {
        setClientSideError(validationResult)
      }
    }
  }

  const handleRegisterSuccess = () => {
    // Set form fields value to empty.
    setErrorMessage("")
    setUsername("")
    setEmail("")
    setPassword("")

    // localStorage.setItem( 'registration-success', 'yes' );

    // Add a message.
    setSuccessMessage("Registration Successful! . You will be logged in now...")
  }

  const classes = useStyles()

  return (
    <div className="register-wrapper">
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <AccountCircleIcon />
          </Avatar>
          <Typography component="h1" variant="h4">
            Sign Up
          </Typography>
          <form
            className={classes.form}
            onSubmit={event => handleRegister(event)}
          >
            <TextField
              id="username-register"
              name="username-register"
              value={username}
              onChange={event => setUsername(event.target.value)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              value={email}
              onChange={event => setEmail(event.target.value)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
            />
            <TextField
              value={password}
              onChange={event => setPassword(event.target.value)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password-register"
              label="Password"
              type="password"
              id="password-register"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            {registerLoading ? (
              <img
                className="register-wrapper__loading"
                src={cartSpinnerGif}
                alt="loading"
              />
            ) : (
              ""
            )}
            {"" !== errorMessage
              ? showAlertBar && (
                  <MessageAlert
                    message={errorMessage}
                    success={false}
                    onCloseButtonClick={onCloseButtonClick}
                  />
                )
              : ""}
            {"" !== successMessage
              ? showAlertBar && (
                  <MessageAlert
                    message={successMessage}
                    success={true}
                    onCloseButtonClick={onCloseButtonClick}
                  />
                )
              : ""}
            <Button type="submit" styles="btn btn__blue btn__blue--fluid">
              Sign Up
            </Button>
          </form>
        </div>
      </Container>
    </div>
  )
}

export default Register
