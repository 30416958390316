import React, { useState, useContext } from "react"
import MessageAlert from "../message-alert"
import Button from "../button"
import Avatar from "@material-ui/core/Avatar"
import TextField from "@material-ui/core/TextField"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Link from "@material-ui/core/Link"
import Grid from "@material-ui/core/Grid"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import { AppContext } from "../context/AppContext"
import { navigate } from "gatsby"
import cartSpinnerGif from "../../images/cart-spinner.gif"
import { sanitize, setAuth } from "../../utils/functions"
import { isEmpty } from "lodash"
import { useMutation } from "@apollo/client"
import LOGIN from "../../mutations/login"
import { v4 } from "uuid"
import validateAndSanitizeLoginForm from "../../validator/login"
import "./index.scss"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "calc(100vh - 100px)",
    justifyContent: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}))

const Login = () => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useContext(AppContext)
  const [errorMessage, setErrorMessage] = useState(null)
  const [loginFields, setLoginFields] = useState({
    username: "",
    password: "",
  })

  if (isUserLoggedIn) {
    navigate("/app/welcome")
  }

  const onCloseButtonClick = () => {
    setErrorMessage("")
  }

  // Login Mutation.
  const [login, { loading: loginLoading, error: loginError }] = useMutation(
    LOGIN,
    {
      variables: {
        input: {
          clientMutationId: v4(), // Generate a unique id.,
          username: loginFields.username,
          password: loginFields.password,
        },
      },
      onCompleted: data => {
        // If error.
        if (!isEmpty(loginError)) {
          setErrorMessage(loginError.graphQLErrors[0].message)
        }

        const { login } = data
        const authData = {
          authToken: login.authToken,
          user: login.user,
        }

        setAuth(authData)
        navigate("/app/welcome")
        setIsUserLoggedIn(true)
      },
      onError: error => {
        if (error) {
          if (!isEmpty(error)) {
            setErrorMessage(error.graphQLErrors[0].message)
          }
        }
      },
    }
  )

  const onFormSubmit = event => {
    event.preventDefault()
    setErrorMessage(null)
    // Validation and Sanitization.
    const validationResult = validateAndSanitizeLoginForm({
      username: loginFields.username,
      password: loginFields.password,
    })

    if (validationResult.isValid) {
      setLoginFields({
        username: validationResult.sanitizedData.username,
        password: validationResult.sanitizedData.password,
      })
      login()
    } else {
      setClientSideError(validationResult)
    }
  }

  const setClientSideError = validationResult => {
    if (validationResult.errors.password) {
      setErrorMessage(validationResult.errors.password)
    }

    if (validationResult.errors.username) {
      setErrorMessage(validationResult.errors.username)
    }
  }

  const handleOnChange = event => {
    setLoginFields({ ...loginFields, [event.target.name]: event.target.value })
  }

  const { username, password } = loginFields
  const classes = useStyles()
  return (
    <div className="login-wrapper">
      <Container component="main" maxWidth="xs">
        {/*  <CssBaseline /> */}
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h4">
            Sign in
          </Typography>
          <form className={classes.form} onSubmit={onFormSubmit}>
            <TextField
              id="username"
              name="username"
              value={username}
              onChange={handleOnChange}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              value={password}
              onChange={handleOnChange}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            {!isEmpty(errorMessage) && (
              <MessageAlert
                message={errorMessage}
                success={false}
                onCloseButtonClick={onCloseButtonClick}
              />
            )}
            {loginLoading && (
              <img
                className="login-wrapper__loading"
                src={cartSpinnerGif}
                alt="loading"
              />
            )}
            <Button type="submit" styles="btn btn__blue btn__blue--fluid">
              Sign In
            </Button>

            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/app/register/" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </div>
  )
}
export default Login
